import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Spinner from 'react-spinkit';
import FinalField from '../../../lib/FinalField';
import YooCaptcha from '../../../lib/YooCaptcha';
import YooField from "../../../lib/YooField";
import YooForm from '../../../lib/YooForm';
import SvgContact from './icons/SvgContact';
import SvgRocket from './icons/SvgRocket';
import SvgProject from './icons/SvgProject';
import Skeleton from 'react-loading-skeleton';




class App extends Component {
  handleSubmit = (event) => {
    console.log('handleSubmit', event)
  }
  getTerms = (langs) => {
    return (
      <span>{langs.ACCEPT} <a target="_blank" href={langs.TERMSLINK}>{langs.TERMS}</a></span>
    )
  }
  loadingSkeleton() {
    return(
      <div className="sl-form" style = {{ textAlign: 'center' }}>
        <Skeleton height={30} width={'20%'} />
        <div className="group-startup">
          <div className="illustration">
              <div className="picture">
              <Skeleton height={90} width={90}/>
              </div>
            <Skeleton width={90}/>
          </div>
        </div>
        <Container>
          <Row className="idcard">
            <Col md={3}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton className="logo-container" height={195}/>
                </div>
              </div>
            </Col>
            <Col md={9}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={128} />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
            </Col>
            <div className="group-startup">
              <div className="illustration">
                  <div className="picture">
                  <Skeleton height={90} width={90}/>
                  </div>
                <Skeleton width={90}/>
              </div>
            </div>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                <Skeleton height={128} />
                </div>
              </div>
            </Col>
            <div className="group-startup">
              <div className="illustration">
                  <div className="picture">
                  <Skeleton height={90} width={90}/>
                  </div>
                <Skeleton width={90}/>
              </div>
            </div>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-group">
                <div className="form-group-container">
                  <Skeleton height={45} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  render() {
    const color1 = "#003594";
    const color2 = "#121324";
    const textColor = "#11113B"
    const bgColor = "#F2F2F2"
    const phColor = "#6c757d"

    const selectStyles = {
      control: (styles, { isFocused }) => {
        return {
          ...styles,
          border: 'none',
          backgroundColor: isFocused
            ? 'white'
            : bgColor,
          padding: '5px 2px',
          borderRadius: '7px',
          boxShadow: isFocused
            ? '0 0 0 0.2rem #bedcff'
            : 'none',
          marginBottom: 0,
          ':hover': {
            ...styles[':hover'],
            boxShadow: '0 0 0 0.2rem #bedcff'
          }
        }
      },
      option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isSelected ? color1 : isFocused ? color1 : null,
          padding: "2px 10px",
          color: isDisabled
            ? '#ccc'
            : isSelected
              ? 'white'
              : isFocused
                ? 'white'
                : textColor,
          cursor: isDisabled ? 'not-allowed' : 'pointer',

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? color1 : null),
          },
        };
      },
      placeholder: (styles) => {
        return {
          ...styles,
          color: phColor
        }
      },
      multiValue: (styles) => {
        return {
          ...styles,
          backgroundColor: color1
        };
      },
      multiValueLabel: (styles) => ({
        ...styles,
        color: 'white'
        // paddingBottom: 0
      }),
      multiValueRemove: (styles) => ({
        ...styles,
        color: 'white',
        ':hover': {
          backgroundColor: color2,
          color: 'white',
        },
      }),
    }

    
    return (
      <YooForm
      loadingComponent={this.loadingSkeleton()}
        render={({ submitError, submitting, fields, langs, bringtoTop, disableSubmit }) => (
          <div className="sl-form">
            {submitting && <div className="overlay">
            <Spinner name="pulse" color="#C09103" />
              <div className="loader-text">{langs.loaderText}</div>
            </div>
            }
            <h2>{langs.formTitle}</h2>

            <div className="group-startup">
              <div className="illustration">
                <div className="picture">
                  <SvgRocket color1={color1} color2={color2} />
                </div>
                <p>{langs.companyStartup}</p>
              </div>

              <Container>
                <Row className="idcard">
                  <Col md={3}>
                    <YooField field="candid-logo" className="logo-container" />
                  </Col>
                  <Col md={9}>
                    <YooField field="candid-intitule" />
                    <YooField field="candid-theme" />
                    <YooField field="candid-url" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <YooField field="candid-adresse" />
                  </Col>
                  <Col md={12}>
                    <YooField field="candid-pays" />
                  </Col>
                  <Col md={12}>
                    <YooField field="candid-description" styles={selectStyles} />
                  </Col>
                  <Col md={12}>
                    <YooField field="candid-pj" styles={selectStyles} />
                  </Col>
                  <Col md={12}>
                  <div className="illustration">
                    <div className="picture">
                      <SvgContact color1={color1} color2={color2} />
                    </div>
                    <p>{langs.contactStartup}</p>
                  </div>
                  </Col>
                  <Col md={12}>
                    <YooField field="candid-contact-su.contact-surname" />
                  </Col>
                  <Col md={12}>
                    <YooField field="candid-contact-su.contact-firstname" />
                  </Col>
                  <Col md={12}>
                    <YooField field="candid-contact-su.contact-mail" />
                  </Col>
                  <Col md={12}>
                    <YooField field="candid-contact-su.contact-phonenumber" />
                  </Col>
                  <Col md={12}>
                    {/* Revoir le lien du PDF pour le reglèment */}
                    <FinalField
                      langs={langs}
                      label={this.getTerms(langs)}
                      name="rules"
                      type="checkbox"
                      required
                    />
                  </Col>
                  <Col md={12}>
                    <YooCaptcha />
                    {submitError && <div className="helpblock">{submitError}</div>}

                    {fields && (
                      <div className="buttons">
                        <Button
                          type="submit"
                          disabled={submitting || disableSubmit}
                          onClick={bringtoTop}
                          className="fileUploader button-action button-action-red"
                          size="lg"
                          block
                        >
                          {disableSubmit ? langs.sendingFiles : langs.submitText}
                        </Button>

                      </div>
                    )}
                  </Col>
                </Row>
              </Container>

            </div>
          </div>
        )
        } />
    );
  }
}

export default App;
